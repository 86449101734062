import moment from "moment"
import qs from "qs"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { IconInfo, IProjectInfo } from "components/IdoCard/ido-interface"
import { Picture } from "pages/ProjectDetails/types"
// import { GrowSliderType } from "pages/Grow/components/GrowSlider/GrowSlider"

export interface IBuyPoolzLinks {
  id: number
  Name: string
  URL: string
  Order: number
  Icon: any[]
}

export interface IFooters {
  id: number
  Category: string
  Text: string
  URL: string
  NewWindow: boolean
}

export interface IChains {
  id: number
  chainMainCoin: string
  chainId: number
  DisplayText: string
  WhiteLogo: IconInfo
  Name: string
  DarkLogo: IconInfo
  isTest: boolean
  IsEVM: boolean
  Show: true
  colorIcon: {
    id: number
    ColorCode: string
    icon: IconInfo
  }
}

export interface IInfoPage {
  id: number
  Type: string
  value: string
  annotation: string | null
}

export interface IGrants {
  id: number
  Name: string
  Text: string
  Link: string
  published_at: string
  created_at: string
  updated_at: string
  icon: IconInfo
}

export interface IMedia {
  id: number
  Name: string
  Text: string
  Link: {
    id: number
    name: string
    url: string
  }
}

export interface IPartnerLogo {
  id: number
  Name: string
  DarkLogo: IconInfo
}

interface ProjectBackground {
  id: number
  picture: Picture
}

export interface IRefunds {
  id: number
  tokenAddress: string
  creatorAddress: string
  startTime: string
  finishTime: string
  startFinishTime: { StartTime: string; FinishTime: string }[]
}

interface ILockTokenWhitelists {
  id: number
  Name: string
  Address: string
  Type: "Envelope" | "OriginalToken" | "Synthetic"
}

export interface IStakingFaq {
  id: number
  ShortText: string
  LongText: string
}

export interface IStakingCoolDowns {
  id: number
  Amount: string
  CoolDownPeriod: string
  type: number
  text: string
}

export interface IMarketCapBadges {
  id: number
  Link: string
  icon: IconInfo
}

export interface IMainCTA {
  id: number
  text: string
  backColor: string | null
  TextColor: string | null
  url: string | null
  ctaText: string | null
  addText: string | null
  flag: boolean | null
  hoverBackColor: string | null
}

export interface IHtmlColorText {
  id: number
  text: string
  color: string
  url?: string
}

export interface IIconText {
  id: number
  text: string
  smallText: string
  icon: IconInfo
}

interface IStaticPage {
  id: number
  Title: string
  smallText: string
  htmlColorText?: IHtmlColorText[]
  iconText?: IIconText[]
  MainCTA?: IMainCTA[]
  sliders?: Picture[]
  TopBg?: Picture
  TopBgUrl?: string
  BOT_CTA_Text?: string
}

interface Badge {
  BackgroundColor: string | null
  Name: string
  TextColor: string | null
  TooltipText: string | null
  id: number
}

export interface IGrow {
  id: number
  reward: string
  text: string
  projectName: string
  participants: number
  startTime: string | null
  finishTime: string | null
  subTitle: string | null
  mainIcon: IconInfo
  mainPicture: Picture
  data_ccode: string | null
  DescriptionMainText: string | null
  DescriptionSubText: string | null
  grow_badges: Badge[] | null
  Banner: Picture | null
  BannerText: string | null
  BannerUrl: string | null
  order: null | number
  showInMainBanner: boolean | null
  Show: boolean | null
}

export interface INonEvmChains {
  id: number
  DisplayText: string
  Regex: string | null
  Icon: {
    url: string
  }
}
const getQueryStringUpcomingIdos = qs.stringify({
  _where: { _and: [{ _or: [{ IsTimeTBA: true }, { FinishTime_gte: moment().format() }] }, { IsShow: true }] }
})

const getQueryStringPastIdos = qs.stringify({
  _where: { _and: [{ IsShow: true }, { FinishTime_lte: moment().format() }] }
})

const transformResponseWhitelist = (baseQueryReturnValue: ILockTokenWhitelists[]) => {
  const list = baseQueryReturnValue.map((item) => item.Address.toLowerCase())
  const LocallyTestList = import.meta.env.VITE_APP_TEST_WHITELIST as string
  if (LocallyTestList) list.push(...LocallyTestList.split(",").filter((address) => address.trim()))
  return [...new Set([...list])]
}

export interface IPageInfos {
  id: number
  Link: string
  Title: string
}

export const admin = createApi({
  reducerPath: "admin",
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_APP_ADMIN }),
  endpoints: (build) => ({
    getBuyPoolzLinks: build.query<IBuyPoolzLinks[], void>({
      query: () => `buy-poolzs?_sort=order`
    }),
    getChains: build.query<IChains[], string | void>({
      query: (query = "") => `chain-settings/${query}`
    }),
    getFooters: build.query<IFooters[], void>({
      query: () => `footers`
    }),
    getInfoPage: build.query<IInfoPage, string>({
      query: (id: string) => `texts/${id}`
    }),
    getGrants: build.query<IGrants[], void>({
      query: () => `open-grants`
    }),
    getMedia: build.query<IMedia[], void>({
      query: () => `media-kits`
    }),
    getPartners: build.query<IPartnerLogo[], void>({
      query: () => `our-partners`
    }),
    getUpcomingIdos: build.query<IProjectInfo[], void>({
      query: () => `Projects-Informations?${getQueryStringUpcomingIdos}&_sort=IsTimeTBA:ASC,StartTime:ASC`
    }),
    getProjectInfoWithId: build.query<IProjectInfo, string>({
      query: (id: string) => `/Projects-Informations/${id}`
    }),
    getProjectBackground: build.query<ProjectBackground, number>({
      query: (id: number) => `project-backgrounds/${id}`
    }),
    getProjectBackgroundCount: build.query<number, void>({
      query: () => `project-backgrounds/count`
    }),
    getMainListShowIdos: build.query<IProjectInfo[], void>({
      query: () => `Projects-Informations?MainListShow=true&_sort=ATHROI:DESC`
    }),
    getPastIdos: build.query<IProjectInfo[], any>({
      query: ({ search = "", page = 0, limit = 10, sort = "ATHROI:DESC", filter = "" }) =>
        `Projects-Informations?${getQueryStringPastIdos}&${qs.stringify({
          _sort: sort,
          _limit: limit,
          _start: page * limit,
          Name_contains: search,
          _where: {
            "chain_setting.chainId": filter === "" ? [] : filter
          }
        })}`
    }),
    getPastIdosWithPoolzBackId: build.query<IProjectInfo[], any>({
      query: ({ search = "", page = 0, limit = 10, sort = "ATHROI:DESC" }) =>
        `Projects-Informations?${getQueryStringPastIdos}&${qs.stringify({
          _sort: sort,
          _limit: limit,
          _start: page * limit,
          Name_contains: search,
          PoolzBackId_null: false
        })}`
    }),
    getPastIdosCount: build.query<number, void>({
      query: () => `Projects-Informations/count`
    }),
    getLockTokenWhitelistsByChain: build.query<string[], { type: string; chainId: number }>({
      query: ({ type, chainId }) => `lock-token-whitelists?Type=${type}&chain_settings.chainId=${chainId}&_limit=1000`,
      transformResponse: transformResponseWhitelist
    }),
    getRefundTokens: build.query<IRefunds[], void>({
      query: () => `refunds`,
      transformResponse: (response: IRefunds[]) =>
        response.filter(({ startTime, finishTime, startFinishTime }) => {
          const sT = new Date(startTime).getTime()
          const fT = new Date(finishTime).getTime()
          const now = Date.now()
          if (sT < now && fT > now) return true

          return (startFinishTime ?? []).some(({ StartTime, FinishTime }) => {
            const sT = new Date(StartTime).getTime()
            const fT = new Date(FinishTime).getTime()
            return sT < now && fT > now
          })
        })
    }),
    getStakingFaqs: build.query<IStakingFaq[], void>({
      query: () => `vault-faqs`
    }),
    getStakingCoolDowns: build.query<IStakingCoolDowns[], void>({
      query: () => `staking-cool-downs`
    }),
    getMarketCapBadges: build.query<IMarketCapBadges[], void>({ query: () => `market-cap-badges` }),
    getStaticPage: build.query<IStaticPage, number>({
      query: (id: number) => `static-pages/${id}`
    }),
    getStaticPageByName: build.query<IStaticPage, string>({
      query: (name: string) =>
        `static-pages?${qs.stringify({
          _where: { pageName: name }
        })}`,
      transformResponse: (response: IStaticPage[]) => response[0]
    }),
    getGrows: build.query<IGrow[], string>({
      query: (periodSelected: string) => {
        const _where = []

        if (periodSelected === "Active") {
          _where.push({ startTime_lte: moment().format() })
          _where.push({ finishTime_gte: moment().format() })
        } else if (periodSelected === "Upcoming") {
          _where.push({ startTime_gt: moment().format() })
        } else if (periodSelected === "Ended") {
          _where.push({ finishTime_lt: moment().format() })
        }
        _where.push({ Show: true })
        return `grows?_sort=order:DESC&${qs.stringify({
          _limit: 1000,
          _where
        })}`
      }
    }),
    getGrowsById: build.query<IGrow, string>({
      query: (id: string) => `grows/${id}`
    }),
    getNonEvmChains: build.query<INonEvmChains[], void>({
      query: () => `non-evm-chains`
    }),
    getPageInfo: build.query<IPageInfos[], void>({
      query: () => `page-infos`
    })
  })
})

export const {
  useGetBuyPoolzLinksQuery,
  useGetFootersQuery,
  useGetPartnersQuery,
  useGetMediaQuery,
  useGetChainsQuery,
  useGetGrantsQuery,
  useGetInfoPageQuery,
  useGetMainListShowIdosQuery,
  useGetUpcomingIdosQuery,
  useGetProjectInfoWithIdQuery,
  useGetProjectBackgroundQuery,
  useGetProjectBackgroundCountQuery,
  useGetPastIdosQuery,
  useGetPastIdosCountQuery,
  useGetLockTokenWhitelistsByChainQuery,
  useGetRefundTokensQuery,
  useGetStakingFaqsQuery,
  useGetStakingCoolDownsQuery,
  useGetMarketCapBadgesQuery,
  useGetStaticPageQuery,
  useGetGrowsQuery,
  useGetStaticPageByNameQuery,
  useGetGrowsByIdQuery,
  useGetNonEvmChainsQuery,
  useGetPageInfoQuery,
  useGetPastIdosWithPoolzBackIdQuery
} = admin
